<template>
  <div class="room-checklist-item">
    <div class="status">
      <a-checkbox v-model:checked="status" @click="checkEnableEdit">
        <span>{{ value.body }}</span>
        <span v-if="value.isMandatory" class="has-text-danger"> * </span>
      </a-checkbox>
    </div>
    <div class="example">
      <div v-if="value.itemImage" class="img-outer" @click="showLightBox">
        <img :src="exampleImage" alt="example" />
      </div>
    </div>
    <div class="before">
      <div v-if="imageBefore" class="img-outer" @click="showLightBox">
        <img :src="imageBefore" alt="before" />
      </div>
      <UploadSingleImage
        v-else
        v-model:value="imageBefore"
        :action="imageBeforeUrl"
        :headers="apiHeaders"
        :button-text="$t('Before')"
        :is-disabled="!isStarted.value"
        @click="checkEnableEdit"
      />
    </div>
    <div class="after">
      <div v-if="value.after" class="img-outer" @click="showLightBox">
        <img :src="imageAfter" alt="after" />
      </div>
      <UploadSingleImage
        v-else
        v-model:value="imageAfter"
        :action="imageAfterUrl"
        :headers="apiHeaders"
        :button-text="$t('After')"
        :is-disabled="!isStarted.value"
        @click="checkEnableEdit"
      />
    </div>
    <LightBox
      :visible="activeLightBox"
      :gallery="gallery"
      @close="activeLightBox = false"
      @del="delImage"
    />
  </div>
</template>

<i18n>
{
"en": {
"Before": "Before",
"After": "After"
},
"ja": {
"Before": "前",
"After": "後"
}
}
</i18n>

<script>
import UploadSingleImage from '@/components/UploadSingleImage';
import LightBox from '@/views/housekeeper-tasks/components/LightBox';
import imageUrl from '@/filters/imageUrl';

import { getToken } from '@/utils/auth';

export default {
  name: 'RoomChecklistItem',
  components: { LightBox, UploadSingleImage },
  inject: ['isStarted'],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeLightBox: false,
      status: false,
      apiHeaders: {
        authorization: `Bearer ${getToken()}`,
      },
    };
  },
  computed: {
    imageBeforeUrl() {
      return `${process.env.VUE_APP_API_URL}/roomAssignments/${this.value.roomAssignmentId}/tasks/${this.value.id}/images/before`;
    },
    imageAfterUrl() {
      return `${process.env.VUE_APP_API_URL}/roomAssignments/${this.value.roomAssignmentId}/tasks/${this.value.id}/images/after`;
    },
    exampleImage() {
      if (this.value.itemImage) {
        return imageUrl(this.value.itemImage.path);
      }

      return '';
    },
    imageBefore() {
      if (this.value.images.length) {
        const image = this.value.images.find((img) => !img.isAfter);

        return image ? imageUrl(image.path) : '';
      }

      return '';
    },
    imageAfter() {
      if (this.value.images.length) {
        const image = this.value.images.find((img) => img.isAfter);

        return image ? imageUrl(image.path) : '';
      }

      return '';
    },
    gallery() {
      const gallery = [];
      if (this.value.itemImage) {
        gallery.push({ url: this.exampleImage, key: 'example' });
      }
      if (this.imageBefore) {
        gallery.push({ url: this.imageBefore, key: 'before' });
      }
      if (this.imageAfter) {
        gallery.push({ url: this.imageAfter, key: 'after' });
      }
      return gallery;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.status = nv.completedAt !== null;
        }
      },
    },
  },
  methods: {
    async checkEnableEdit(ev) {
      if (!this.isStarted.value) {
        ev.stopPropagation();
        ev.preventDefault();
        this.$emitter.emit('room-checklist-item-edit', false);
      } else if (ev.target.type === 'checkbox') {
        let res;
        const payload = {
          assignmentId: this.value.roomAssignmentId,
          id: this.value.id,
        };
        if (ev.target.checked) {
          res = await this.$store.dispatch('housekeeper-tasks/checkedChecklist', payload);
        } else {
          res = await this.$store.dispatch('housekeeper-tasks/uncheckedChecklist', payload);
        }
        this.$emitter.emit('room-checklist-item-edit', {
          ...res,
          category: this.value && this.value.category,
        });
      }
    },
    showLightBox(ev) {
      this.checkEnableEdit(ev);
      if (this.isStarted.value) {
        this.activeLightBox = true;
      }
    },
    delImage(key) {
      this.activeLightBox = false;
      if (key) this.value[key] = ''; // eslint-disable-line
    },
  },
};
</script>

<style lang="scss" scoped>
.img-outer {
  width: 80px;
  height: 80px;
  object-fit: cover;
  @include mobile {
    width: 50px;
    height: 50px;
  }
}

.room-checklist-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
}

.status {
  margin-right: auto;
}

.example {
  margin-right: 80px;
  @include mobile {
    margin-right: 10px;
  }
}

.before {
  margin-right: 80px;
  @include mobile {
    margin-right: 10px;
  }
}

.after {
  margin-right: 0px;
}
</style>

<template>
  <div class="room">
    <div class="heading" @click="toggleRoom">
      <div class="heading-title">
        {{ value.name }}
      </div>
      <div class="heading-ctrl">
        <span>{{ done }}/{{ total }} {{ $t('done') }}</span>
        <svg
          :class="{ collapse }"
          width="12"
          height="9"
          viewBox="0 0 12 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6 9L12 0L7.86805e-07 -1.04907e-06L6 9Z" fill="#888888" />
        </svg>
      </div>
    </div>
    <div class="room-checklist" :class="{ 'is-hidden': collapse }">
      <!-- eslint-disable -->
      <RoomChecklistItem
        v-for="(item, idx) in value.tasks"
        :key="item.id"
        v-model:value="value.tasks[idx]"
      />
      <!-- eslint-enable -->
    </div>
  </div>
</template>

<i18n>
{
"en": {
"done": "done"
},
"ja": {
"done": "完了"
}
}
</i18n>

<script>
import RoomChecklistItem from '@/views/housekeeper-tasks/components/RoomChecklistItem';

export default {
  name: 'Room',
  components: { RoomChecklistItem },
  inject: ['firstRoom'],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      collapse: true,
    };
  },
  computed: {
    total() {
      return this.value.tasks.length;
    },
    done() {
      return this.value.tasks.filter((i) => i.completedAt).length;
    },
  },
  created() {
    if (this.firstRoom === this.value) {
      this.collapse = false;
    }
  },
  methods: {
    toggleRoom() {
      this.collapse = !this.collapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  display: flex;
  cursor: pointer;
  user-select: none;
  padding: 12px 15px;
  background: #f5f5f5;
  margin-bottom: 3px;
}

.heading-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.heading-ctrl {
  margin-left: auto;
  color: #333;
  font-size: 14px;

  svg {
    margin-left: 1rem;

    &.collapse {
      transform: rotate(270deg);
    }
  }
}

.room-checklist {
  margin-top: 8px;
}
</style>

<template>
  <div>
    <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
      {{ $t('title') }}
    </h2>
    <p class="m-b-6x">
      {{ $t('desc') }}
    </p>
    <p class="has-text-right">
      <a-button type="primary" size="large" style="min-width: 120px" @click="$emit('cancel')">
        {{ $t('OK') }}
      </a-button>
    </p>
  </div>
</template>

<i18n>
{
"en": {
"title": "Task not started yet",
"desc": "Please click ‘START’ before ticking off the checklist.",
"OK": "OK"
},
"ja": {
"title": "タスクが始まっておりません",
"desc": "チェックリスト確認前に「スタート」ボタンを押してください",
"OK": "OK"
}
}
</i18n>

<script>
export default {
  name: 'ChecklistNotStarted',
  emits: ['cancel'],
};
</script>

<style scoped></style>

<template>
  <div class="section">
    <div v-if="list" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <SearchForm :property="task.room.property" :room="task.room" @filter="fetchRoomData" />
        </div>
        <div class="column is-narrow">
          <a-button
            v-permission="['add-out-of-order']"
            :size="size"
            style="min-width: 180px"
            class="btn-mobi-full"
            type="primary"
            @click="handleAdd"
          >
            {{ $t('Add Out of Order') }}
          </a-button>
        </div>
      </div>
      <OutOfOrder
        :value="list"
        :pagination="pagination"
        @table-change="handleTableChange"
        @delete="fetchRoomData"
      />
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Add Out of Order": "Add Out of Order"
},
"ja": {
"Add Out of Order": "使用不可を追加"
}
}
</i18n>

<script>
import { mapState } from 'vuex';
import OutOfOrder from '@/views/housekeeper-tasks/components/OutOfOrder';
import SearchForm from '@/views/out-of-order/components/SearchForm';
import list from '@/views/out-of-order/mixins/list';

export default {
  name: 'HousekeeperTaskOutOfOrder',
  components: {
    OutOfOrder,
    SearchForm,
  },
  mixins: [list],
  data() {
    return {
      submitting: false,
      size: 'large',
    };
  },
  computed: {
    ...mapState('housekeeper-tasks', ['task']),
  },
  watch: {
    $route(route) {
      if (route.name === 'housekeeper-task-out-of-order') {
        this.fetchRoomData();
      }
    },
  },
  created() {
    this.fetchRoomData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchRoomData();
    },
    fetchRoomData() {
      return this.fetchData({ roomId: this.task.room.id });
    },
    handleAdd() {
      this.$router.push({
        name: 'out-of-order-add',
        query: {
          propertyId: this.task.room.propertyId,
          roomId: this.task.room.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

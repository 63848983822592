<template>
  <div class="user">
    <template v-if="!loading">
      <AdminSubMenu v-if="task" :menu="menu" :sub-menu="subMenu">
        {{ task.room && task.room.name }}
        <template #description>
          {{ $filters.date(task.cleaningDate, { format: $variables.dateFormat }) }}
          {{ task.cleaningTime }}
        </template>
      </AdminSubMenu>
      <router-view />
    </template>
  </div>
</template>

<i18n>
{
"en": {
"Overview": "Overview",
"Checklist": "Checklist",
"Stock": "Stock",
"Lost & Found": "Lost & Found",
"Out of Order": "Out of Order",
},
"ja": {
"Overview": "概要",
"Checklist": "チェックリスト",
"Stock": "在庫",
"Lost & Found": "忘れ物",
"Out of Order": "使用不可",
}
}
</i18n>

<script>
import { mapState } from 'vuex';
import previousRoute from '@/components/mixins/previousRoute';
import AdminSubMenu from '@/components/AdminSubMenu';

export default {
  name: 'HousekeeperTask',
  components: { AdminSubMenu },
  mixins: [previousRoute],
  data() {
    return {
      menuName: 'housekeeper-tasks',
    };
  },
  computed: {
    menu() {
      return {
        query: this.previousRouteQuery,
        name: this.menuName,
        title: this.$t('Back'),
      };
    },
    subMenu() {
      return [
        {
          name: 'housekeeper-task-overview',
          title: this.$t('Overview'),
        },
        {
          name: 'housekeeper-task-checklist',
          title: this.$t('Checklist'),
        },
        {
          name: 'housekeeper-task-stock',
          title: this.$t('Stock'),
        },
        {
          name: 'housekeeper-task-lost-found',
          title: this.$t('Lost & Found'),
        },
        {
          name: 'housekeeper-task-out-of-order',
          title: this.$t('Out of Order'),
        },
      ];
    },
    childrenRoutes() {
      return [
        'housekeeper-task-overview',
        'housekeeper-task-checklist',
        'housekeeper-task-stock',
        'housekeeper-task-lost-found',
        'housekeeper-task-out-of-order',
      ];
    },
    ...mapState('housekeeper-tasks', ['task', 'loading']),
  },
  watch: {
    /* $route(to) {
      if (this.childrenRoutes.indexOf(to.name) !== -1) {
        this.asyncData(to)
      }
    } */
  },
  created() {
    this.asyncData();
  },
  methods: {
    async asyncData(route = null) {
      route = route || this.$route;
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        this.loading = true;
        this.task = await this.$store.dispatch('housekeeper-tasks/get', { id: route.params.id });
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
  },
};
</script>

<style scoped></style>

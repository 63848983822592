<template>
  <div class="rooms">
    <Room v-for="item in value" :key="item.id" :value="item" />
  </div>
</template>

<script>
import Room from '@/views/housekeeper-tasks/components/Room';

export default {
  name: 'Rooms',
  components: { Room },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="section">
    <div v-if="list" class="container">
      <div class="columns is-mobi">
        <div class="column">
          <SearchForm :room="task.room" @filter="fetchRoomData" />
        </div>
        <div class="column is-narrow">
          <a-button
            v-permission="['add-lost-and-found']"
            :size="size"
            style="min-width: 180px"
            class="btn-mobi-full"
            type="primary"
            @click="handleAdd"
          >
            {{ $t('Add Lost & Found') }}
          </a-button>
        </div>
      </div>
      <LostFound
        :value="list"
        :pagination="pagination"
        @table-change="handleTableChange"
        @delete="fetchRoomData"
      />
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Add Lost & Found": "Add Lost & Found"
},
"ja": {
"Add Lost & Found": "忘れ物を追加"
}
}
</i18n>

<script>
import { mapState } from 'vuex';
import LostFound from '@/views/housekeeper-tasks/components/LostFound';
import SearchForm from '@/views/lost-found/components/SearchForm';
import list from '@/views/lost-found/mixins/list';

export default {
  name: 'HousekeeperTaskLostFund',
  components: {
    LostFound,
    SearchForm,
  },
  mixins: [list],
  data() {
    return {
      submitting: false,
      size: 'large',
    };
  },
  computed: {
    ...mapState('housekeeper-tasks', ['task']),
  },
  watch: {
    $route(route) {
      if (route.name === 'housekeeper-task-lost-found') {
        this.fetchRoomData();
      }
    },
  },
  created() {
    this.fetchRoomData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchRoomData();
    },
    fetchRoomData() {
      return this.fetchData({ roomId: this.task.room.id });
    },
    handleAdd() {
      this.$router.push({
        name: 'lost-founds-add',
        query: {
          propertyId: this.task.room.propertyId,
          roomId: this.task.room.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

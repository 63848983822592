<template>
  <div class="light-box">
    <!-- eslint-disable -->
    <a-modal
      v-model:visible="visible"
      width="100%"
      centered
      wrap-class-name="slider-dialog"
      :footer="null"
      :after-close="() => $emit('close')"
    >
    <!-- eslint-enable -->
      <div class="slider-container">
        <!-- swiper1 -->
        <Swiper
          ref="swiperTop"
          :space-between="swiperOptionTop.spaceBetween"
          class="gallery-top"
          :controller="{ control: thumbsSwiper }"
          watch-slides-visibility
          watch-slides-progress
          @swiper="setMainSwiper"
          @slideChange="updateIndex"
        >
          <SwiperSlide
            v-for="it in localGallery"
            :key="it.url"
            v-lazy:backgroundImage="{ src: it.url }"
            class="slide-1"
          />
        </Swiper>
        <div class="slider-info">
          <span class="slider-count">{{ activeIndex + 1 }} / {{ gallery.length }}</span>
          <span class="slider-caption">{{ activeSlideKey }}</span>
          <span class="action"><DeleteFilled @click="$emit('del', activeSlideKey)" /></span>
        </div>
        <!-- swiper2 Thumbs -->
        <Swiper
          ref="swiperThumbs"
          :space-between="swiperOptionThumbs.spaceBetween"
          :centered-slides="swiperOptionThumbs.centeredSlides"
          :slides-per-view="swiperOptionThumbs.slidesPerView"
          :touch-ratio="swiperOptionThumbs.touchRatio"
          :slide-to-clicked-slide="swiperOptionThumbs.slideToClickedSlide"
          :navigation="swiperOptionThumbs.navigation"
          class="gallery-thumbs"
          watch-slides-visibility
          watch-slides-progress
          :controller="{ control: mainSwiper }"
          @swiper="setThumbsSwiper"
        >
          <SwiperSlide
            v-for="it in localGallery"
            :key="it.url"
            v-lazy:backgroundImage="{ src: it.url }"
            class="slide-2"
          />
        </Swiper>
      </div>
    </a-modal>
  </div>
</template>

<script>
import 'swiper/swiper.scss';
import SwiperCore, { Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { DeleteFilled } from '@ant-design/icons-vue';

// install Swiper's Thumbs component
SwiperCore.use([Controller]);

export default {
  name: 'LightBox',
  components: {
    Swiper,
    SwiperSlide,
    DeleteFilled,
  },
  props: {
    gallery: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    visible: {
      type: Boolean,
    },
  },
  emits: ['close', 'del'],
  data() {
    return {
      swiperOptionTop: {
        spaceBetween: 10,
      },
      swiperOptionThumbs: {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 9,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperThumbs: null,
      localGallery: JSON.parse(JSON.stringify(this.gallery)),
      thumbsSwiper: null,
      mainSwiper: null,
      activeIndex: -1,
    };
  },
  computed: {
    activeSlide() {
      return this.localGallery.length && this.activeIndex < this.localGallery.length
        ? this.localGallery[this.activeIndex]
        : null;
    },
    activeSlideKey() {
      return this.activeSlide ? this.activeSlide.key : '';
    },
  },
  watch: {
    gallery(nv) {
      this.localGallery = JSON.parse(JSON.stringify(nv));
    },
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
    setMainSwiper(swiper) {
      this.mainSwiper = swiper;
      this.activeIndex = this.mainSwiper.activeIndex;
    },
    updateIndex(slider) {
      this.activeIndex = slider.activeIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-container {
  max-width: 980px;
  height: calc(100vh - 100px);
  margin: 0 auto auto auto;
}

.swiper-slide {
  background-size: auto auto;
  background-position: center;
  background-repeat: no-repeat;
}

.gallery-top {
  height: calc(100% - 140px) !important;
  width: 100%;
}

.slider-info {
  height: 70px !important;
  color: $white;
  font-size: 1rem;
  font-weight: bold;
  padding-top: 12px;
  display: flex;
}

.slider-count {
  margin-right: 60px;
}

.action {
  margin-left: auto;
}

.gallery-thumbs {
  height: 70px !important;
  box-sizing: border-box;
  padding: 0;
}

.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}
</style>

<style lang="scss">
.slider-dialog {
  .ant-modal {
    height: 100%;
    padding-bottom: 0 !important;
  }

  .ant-modal-content {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .el-dialog__close {
    color: $white;
    font-size: 2rem;

    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }

  .slide-1 {
    background-color: #ffffff;
  }

  .slide-2 {
    background-size: cover;
  }

  .ant-modal-close-x {
    color: #ffffff;
    font-size: 24px;
  }

  .ant-modal-body {
    padding-top: 50px;
  }
}
</style>

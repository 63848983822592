<template>
  <div>
    <a-table
      row-key="id"
      :columns="columns"
      :data-source="value"
      :custom-row="rowClicked"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template #checkoutDate="{ record }">
        {{ $filters.date(record.checkoutDate, { format: $variables.dateFormat }) }}
      </template>
      <template #action="{ record }">
        <DelLostFound :record="record" @delete="$emit('delete')" />
      </template>
    </a-table>
  </div>
</template>

<i18n>
{
"en": {
"Lost & Found Name": "Lost & Found Name",
"Description": "Description",
"Checkout date": "Checkout date",
"Status": "Status"
},
"ja": {
"Lost & Found Name": "忘れ物名",
"Description": "詳細",
"Checkout date": "チェックアウト日",
"Status": "ステータス"
}
}
</i18n>

<script>
import DelLostFound from '@/views/lost-found/components/DelLostFound';

export default {
  name: 'LostFound',
  components: {
    DelLostFound,
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    pagination: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ['delete', 'table-change'],
  data() {
    return {
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Lost & Found Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Description'),
          dataIndex: 'description',
          slots: { customRender: 'description' },
        },
        {
          title: this.$t('Checkout date'),
          dataIndex: 'checkoutDate',
          slots: { customRender: 'checkoutDate' },
        },
        {
          title: this.$t('Status'),
          dataIndex: 'status',
          slots: { customRender: 'status' },
          className: 'min-width-150',
          width: '150px',
        },
        {
          title: ' ',
          dataIndex: 'action',
          slots: { customRender: 'action' },
          width: '60px',
        },
      ],
    };
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.$emit('table-change', pagination, filters, sorter);
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$router.push({ name: 'lost-found-edit', params: { id: record.id } });
        },
      };
    },
  },
};
</script>

<style scoped></style>

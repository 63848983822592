<template>
  <div class="section">
    <div v-if="task" class="container">
      <h2 class="has-text-primary has-text-weight-bold is-size-4">
        {{ task.partner?.name || 'N.A.' }}
        <span class="has-text-success-dark is-pulled-right-mobile is-size-7">{{
          $t(task.status)
        }}</span>
      </h2>
      <p class="is-size-7">
        {{ taskUser }}
      </p>
      <table class="task-table">
        <tr>
          <th>{{ $t('Property') }}</th>
          <td>{{ task.room ? task.room.property.name : '-' }}</td>
        </tr>
        <tr>
          <th>{{ $t('Address') }}</th>
          <td>{{ task.room ? task.room.property.address : '-' }}</td>
        </tr>
        <tr>
          <th>{{ $t('Room') }}</th>
          <td>{{ task.room ? task.room.name : '-' }}</td>
        </tr>
        <tr>
          <th>{{ $t('Date') }}</th>
          <td>{{ $filters.date(task.cleaningDate, { format: $variables.dateFormat }) }}</td>
        </tr>
        <tr>
          <th>{{ $t('Cleaning time') }}</th>
          <td>{{ task.cleaningTime || '-' }}</td>
        </tr>
        <tr>
          <th>{{ $t('Same day checkin') }}</th>
          <td>{{ task.sameDayCheckin ? $t('has-checkin') : $t('has-no-checkin') }}</td>
        </tr>
        <tr>
          <th>{{ $t('Checkout status') }}</th>
          <td>{{ checkoutStatus }}</td>
        </tr>
        <tr>
          <th>{{ $t('Housekeeping status') }}</th>
          <td>{{ housekeepingStatus }}</td>
        </tr>
        <tr>
          <th>{{ $t('Previous guest pax') }}</th>
          <td>{{ task.previousGuestPax ?? 'N.A.' }}</td>
        </tr>
        <tr>
          <th>{{ $t('Next guest pax') }}</th>
          <td>{{ task.nextGuestPax ?? 'N.A.' }}</td>
        </tr>
        <tr>
          <th>{{ $t('Remarks') }}</th>
          <td>{{ task.remarks ?? '-' }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Property": "Property",
    "Address": "Address",
    "Room": "Room",
    "Date": "Date",
    "Cleaning time": "Cleaning time",
    "Checkout status": "Checkout status",
    "Housekeeping status": "Housekeeping status",
    "Previous guest pax": "Previous guest pax",
    "Next guest pax": "Next guest pax",
    "yes": "yes",
    "no": "no",
    "Remarks": "Remarks",
    "Same day checkin": "Same day checkin",
    "has-checkin": "yes",
    "has-no-checkin": "no",
    "cleaning-in-progress": "in-progress"
  },
  "ja": {
    "Property": "物件",
    "Address": "施設住所",
    "Room": "部屋",
    "Date": "日付",
    "Cleaning time": "開始",
    "Checkout status": "チェックアウト",
    "Housekeeping status": "清掃",
    "Previous guest pax": "前のゲスト人数",
    "Next guest pax": "次のゲスト人数",
    "yes": "済",
    "no": "未",
    "Remarks": "備考",
    "Same day checkin": "同日イン",
    "has-checkin": "ある",
    "has-no-checkin": "ない",
    "cleaning-in-progress": "清掃中"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import { getFullname } from '@/utils/users';

export default {
  name: 'HousekeeperTaskOverview',
  data() {
    return {
      submitting: false,
    };
  },
  computed: {
    ...mapState('housekeeper-tasks', ['task', 'loading']),
    housekeepingStatus() {
      const today = this.$filters.date(new Date(), 'YYYY-MM-DD');
      const completeDate = this.task.completedAt
        ? this.$filters.date(this.task.completedAt, 'YYYY-MM-DD')
        : '';
      if (!this.task.completedAt) return this.$t('no');
      if (today === completeDate) return this.$t('cleaning-in-progress');
      if (this.task.completedAt) return this.$t('yes');

      return '';
    },
    checkoutStatus() {
      const { checkoutStatus } = this.task;

      if (checkoutStatus === undefined || checkoutStatus === null) {
        return 'N.A.';
      }

      return checkoutStatus === true ? this.$t('yes') : this.$t('no');
    },
    taskUser() {
      const { user } = this.task;

      if (!user) {
        return '-';
      }

      return getFullname({
        fname: user.firstName,
        lname: user.lastName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.task-table {
  margin-top: 2rem;
  font-size: 14px;

  th {
    font-weight: normal;
    color: #888888;
    padding-right: 1rem;
  }
}
</style>

<template>
  <div class="section">
    <div v-if="stocks && stocks.length > 0" class="container">
      <div v-for="(stock, index) in stocks" :key="index">
        <Stock :value="stock.items" />
      </div>
      <div class="has-text-right stock-footer">
        <a-button
          :disabled="loading"
          :size="size"
          class="button-secondary-outline m-r-3x"
          style="min-width: 150px"
          @click="resetFields"
        >
          {{ $t('Reset') }}
        </a-button>
        <a-button
          :loading="loading"
          :size="size"
          style="min-width: 150px"
          type="primary"
          @click="onSubmit"
        >
          {{ $t('Save') }}
        </a-button>
      </div>
    </div>
    <a-empty v-else>
      <template #description>
        <h1>{{ $t('No stock control') }}</h1>
      </template>
    </a-empty>
  </div>
</template>

<i18n>
{
  "en": {
    "Reset": "Reset",
    "Save": "Save",
    "success":"Stock updated.",
    "No stock control": "No stock control associated to room."
  },
  "ja": {
    "Reset": "リセット",
    "Save": "保存",
    "success":"在庫更新。",
    "No stock control": "部屋に関連付けられた在庫コントロールはありません。"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import Stock from '@/views/housekeeper-tasks/components/Stock';
import roomStocks from '@/views/stocks/mixins/room-stocks';

export default {
  name: 'HousekeeperTaskStock',
  components: { Stock },
  mixins: [roomStocks],
  computed: {
    ...mapState('housekeeper-tasks', ['task']),
  },
  watch: {
    $route(route) {
      if (route.name === 'housekeeper-task-stock') {
        this.fetchRoomStocks();
      }
    },
  },
  created() {
    this.fetchRoomStocks();
  },
  methods: {
    fetchRoomStocks() {
      this.room = this.task.room;

      return this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.task-table {
  margin-top: 2rem;
  font-size: 14px;

  th {
    font-weight: normal;
    color: #888888;
    padding-right: 1rem;
  }
}

.start-button {
  min-width: 200px;
  @include mobile {
    width: 100%;
  }
}
</style>

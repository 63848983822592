<template>
  <div class="section">
    <div v-if="checklist" class="container">
      <a-button
        v-if="!startedAt && !isAllTaskFinished"
        type="primary"
        :size="size"
        :disabled="!isAvailableForCleaning"
        class="start-button"
        @click="onStart"
      >
        {{ $t('Start') }}
      </a-button>
      <p v-else>
        <span v-if="isAllTaskFinished">
          {{ $t('finished') }}
        </span>
        <span v-else>
          {{ $t('started', { startedAt: startedAt }) }}
        </span>
      </p>

      <hr>
      <Checklist :value="checklist" />
      <div v-if="startedAt && !completedAt" class="checklist-footer">
        <hr>
        <a-button
          type="primary"
          :size="size"
          class="start-button"
          :disabled="!isFinished"
          @click="onSubmit"
        >
          {{ $t('Finish') }}
        </a-button>
        <p v-if="!isFinished" class="has-text-danger">
          {{ $t('error', { unFinishTasks }) }}
        </p>
      </div>
      <Modal ref="checklistNotStartModal">
        <template #default="{ hide }">
          <ChecklistNotStarted @cancel="hide" />
        </template>
      </Modal>
    </div>
    <a-empty v-else>
      <template #description>
        <h1>{{ $t('No checklist') }}</h1>
      </template>
    </a-empty>
  </div>
</template>

<i18n>
{
  "en": {
    "Start": "Start",
    "finished": "All housekeeper task list has been finished.",
    "started": "The Housekeeping work has started on {startedAt}.",
    "Finish": "Finish",
    "error": "You are missing {unFinishTasks} mandatory tasks",
    "No checklist": "No inspection list associated to room."
  },
  "ja": {
    "Start": "スタート",
    "finished": "必要な清掃は全て完了しました。お疲れ様でした！",
    "started": "{startedAt}から清掃を開始します。",
    "Finish": "完了",
    "error": "必須項目タスク {unFinishTasks} が完了してません",
    "No checklist": "部屋に関連付けられた確認箇所リストはありません。"
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import { computed } from 'vue';
import { mapState } from 'vuex';
import { groupBy } from 'lodash';
import Checklist from '@/views/housekeeper-tasks/components/Checklist';
import Modal from '@/components/Modal';
import ChecklistNotStarted from '@/views/housekeeper-tasks/components/ChecklistNotStarted';
import { STATUS } from '@/config/room-assignments';

export default {
  name: 'HousekeeperTaskChecklist',
  components: { ChecklistNotStarted, Modal, Checklist },
  provide() {
    return {
      isStarted: computed(() => this.startedAt && !this.completedAt),
    };
  },
  data() {
    return {
      submitting: false,
      checklist: undefined,
      size: 'large',
      remainingTask: undefined,
    };
  },
  computed: {
    ...mapState('housekeeper-tasks', ['task', 'loading']),
    unFinishTasks() {
      return this.remainingTask.mandatory;
    },
    isFinished() {
      return this.remainingTask.mandatory === 0;
    },
    isAllTaskFinished() {
      return this.task.status === STATUS.FINISHED;
    },
    startedAt() {
      return this.task.startedAt
        && this.$filters.date(this.task.startedAt, {
          format: this.$variables.datetimeFormat,
        });
    },
    completedAt() {
      return this.task.completedAt
        && this.$filters.date(this.task.completedAt, {
          format: this.$variables.datetimeFormat,
        });
    },
    isAvailableForCleaning() {
      return (
        this.task
        && this.task.cleaningDate
        && (dayjs().isSame(this.task.cleaningDate) || dayjs().isAfter(this.task.cleaningDate))
      );
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'housekeeper-task-checklist') {
        this.asyncData();
      }
    },
  },
  created() {
    this.asyncData();
  },
  mounted() {
    this.$nextTick(() => {
      this.$emitter.on('room-checklist-item-edit', (val) => {
        if (!val && this.$refs.checklistNotStartModal) {
          this.$refs.checklistNotStartModal.show();
        }
        if (typeof val === 'object') {
          this.updateChecklist(val);
        }
      });
    });
  },
  beforeUnmount() {
    this.$emitter.off('room-checklist-item-edit');
  },
  methods: {
    async asyncData() {
      try {
        this.checklist = undefined;
        this.$store.commit('SHOW_FULLSCREEN_LOADER');

        const params = { assignmentId: this.$route.params.id };
        const { list } = await this.$store.dispatch(
          'housekeeper-tasks/getTaskByAssignment',
          params,
        );

        if (list.length) {
          const { count } = await this.$store.dispatch(
            'housekeeper-tasks/getRemainingTask',
            params,
          );

          this.remainingTask = count;

          const lists = [];
          for (let i = 0; i < list.length; i += 1) {
            const { tasks, ...attributes } = list[i];
            const groupedTasks = groupBy(tasks, 'category');

            const categorizedTasks = Object.keys(groupedTasks).map((category) => ({
              name: category,
              tasks: groupedTasks[category],
            }));

            lists.push({
              ...attributes,
              inspections: categorizedTasks,
            });
          }

          this.checklist = lists;
        }
      } catch (e) {
        console.log(e);
      }
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
    async updateChecklist(item) {
      const { data, category } = item;

      this.checklist.forEach(({ inspections }) => {
        const list = inspections.find(({ name }) => name === category);

        if (list) {
          const task = list.tasks.find((t) => t.id === data.id);

          if (task) {
            task.completedAt = data.completedAt;
          }
        }
      });

      const params = { assignmentId: this.$route.params.id };
      const { count } = await this.$store.dispatch(
        'housekeeper-tasks/getRemainingTask',
        params,
      );

      this.remainingTask = count;
    },
    async onStart() {
      // update room assignment status
      await this.$store.dispatch('housekeeper-tasks/start', this.$route.params.id);
      await this.$store.dispatch('housekeeper-tasks/get', { id: this.$route.params.id });
    },
    async onSubmit() {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        await this.$store.dispatch('housekeeper-tasks/finish', this.$route.params.id);
        await this.$store.dispatch('housekeeper-tasks/get', { id: this.$route.params.id });
        await this.asyncData();
        this.$message.success('Checklist updated.');
      } catch (e) {
        console.log(e);
      }
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
  },
};
</script>

<style lang="scss" scoped>
.task-table {
  margin-top: 2rem;
  font-size: 14px;

  th {
    font-weight: normal;
    color: #888888;
    padding-right: 1rem;
  }
}

.start-button {
  min-width: 200px;
  @include mobile {
    width: 100%;
  }
}
</style>

<template>
  <div class="checklist-item">
    <h3 class="has-text-primary has-text-weight-bold is-size-4 m-b">
      {{ value.name }}
    </h3>
    <Rooms :value="value.inspections" />
  </div>
</template>

<script>
import Rooms from '@/views/housekeeper-tasks/components/Rooms';

export default {
  name: 'ChecklistItem',
  components: {
    Rooms,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.checklist-item {
  margin-bottom: 30px;
}
</style>

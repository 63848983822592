<template>
  <div class="checklist">
    <ChecklistItem v-for="item in value" :key="item.id" :value="item" />
  </div>
</template>

<script>
import ChecklistItem from '@/views/housekeeper-tasks/components/ChecklistItem';

export default {
  name: 'Checklist',
  components: { ChecklistItem },
  provide() {
    return {
      firstRoom:
        this.value.length && this.value[0].inspections.length
          ? this.value[0].inspections[0]
          : undefined,
    };
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
